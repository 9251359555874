import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Share from "../components/share"
import ContactForm from "../components/contactForm"
import Tags from "../components/tags"
import styled from "@emotion/styled"
import { css, Global } from "@emotion/react"

export default ({ data }) => {
  const post = data.post
  const cat = data.cat
  // const autres = data.autres

  return (
    <Layout pathEn={post.frontmatter.pathEn}>
      <SEO
        title={post.frontmatter.metaTitle}
        description={post.frontmatter.metaDescription}
      />
      <Helmet>
        {post.frontmatter.tags[0] !== "hospitalisation" && (
          <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "${cat.frontmatter.title}",
            "item": "https://www.docteurphilipperoure.com${cat.fields.slug}"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "${post.frontmatter.title}",
            "item": "https://www.docteurphilipperoure.com${post.fields.slug}"
          }]
      }
    `}</script>
        )}
        <meta
          property="og:image"
          content={`https://www.docteurphilipperoure.com${post.frontmatter.image.childImageSharp.fluid.src}`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="720" />
        <meta property="og:image:height" content="411" />
        <meta
          property="og:url"
          content={`https://www.docteurphilipperoure.com${post.fields.slug}`}
        />
        <meta property="og:title" content={post.frontmatter.metaTitle} />
        <meta
          property="og:description"
          content={post.frontmatter.metaDescription}
        />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="1086796511711258" />
        {post.frontmatter.pathEn !== null && (
          <link
            rel="alternate"
            hreflang="en"
            href={`https://www.docteurphilipperoure.com${post.frontmatter.pathEn}`}
          />
        )}
      </Helmet>
      <Global
        styles={css`
          .summary {
            background-color: rgba(44, 44, 44, 0.97);
            color: white;
            width: 300px;
            font-size: 0.95em;
            padding: 1.1em 1.1em 0.8em;
            font-weight: 300;
            border-radius: 7px;
            float: right;
            margin: 0 0 1em 1em;
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid white;
          }
          .summary p {
            margin: 0 0 0.5em;
            font-weight: 600;
            font-size: 1.15em;
            margin: 0 0.2em 0.5em;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            text-align: center;
            font-family: Raleway, "Times new roman", serif;
          }
          .summary ol {
            padding: 0;
            margin: 0 0 0 1.3em;
            list-style-type: decimal;
          }
          .summary li {
            line-height: 1.4em;
            margin-bottom: 0.6em;
          }
          .summary a {
            color: white;
            text-decoration: none;
          }
          .summary li:hover,
          .summary li:hover a {
            color: #d6af68;
          }
          @media (max-width: 678px) {
            .summary {
              width: 100%;
              float: none;
              margin: 0 0 1em;
              box-sizing: border-box;
              padding: 1em 1.25em 0.75em;
            }
          }
        `}
      />
      <WrapperTitle>
        <WrapperTitleText>
          <Breadcrumb>
            <Link to="/">Accueil</Link> /{" "}
            {post.frontmatter.tags[0] === "hospitalisation" ? (
              <Link to="/hospitalisation/">Hospitalisation</Link>
            ) : (
              <Link to={cat.fields.slug}>{cat.frontmatter.title}</Link>
            )}
          </Breadcrumb>
          <H1 dangerouslySetInnerHTML={{ __html: post.frontmatter.title }} />
          <Intro dangerouslySetInnerHTML={{ __html: post.frontmatter.intro }} />
        </WrapperTitleText>

        <WrapperTitleImage>
          <Img
            fluid={post.frontmatter.image.childImageSharp.fluid}
            css={{ margin: `0 auto` }}
            alt={post.frontmatter.imageAlt}
          />
        </WrapperTitleImage>
      </WrapperTitle>

      <Share slug={post.fields.slug} title={post.frontmatter.title} />
      <WrapperText>
        {/*  {h2sArray.length > 2 && <Summary h2sArray={h2sArray} />} */}
        <div
          dangerouslySetInnerHTML={{ __html: post.html }}
          css={css`
            counter-reset: h2;

            h2:before {
              content: counter(h2, decimal) ". ";
              counter-increment: h2;
            }
          `}
        />
      </WrapperText>
      {post.frontmatter.tags[0] !== "hospitalisation" && (
        <Tags tags={post.frontmatter.tags} />
      )}

      <ContactForm
        about={post.frontmatter.about}
        title={post.frontmatter.title}
      />
      {/* 
      <WrapperText>
        <h3>Autres interventions : {cat.frontmatter.title}</h3>
        <Img
          fluid={cat.frontmatter.image.childImageSharp.fluid}
          css={{ margin: `0 auto` }}
          alt={cat.frontmatter.imageAlt}
        />

        <LinkList>
          {autres.edges.map(({ node }) => {
            return <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          })}
        </LinkList>
      </WrapperText> */}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $tag: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tags
        metaTitle
        metaDescription
        about
        tags
        intro
        pathEn
        image {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        imageAlt
      }
      fields {
        slug
      }
      html
    }
    cat: markdownRemark(frontmatter: { queryTag: { eq: $tag } }) {
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    autres: allMarkdownRemark(
      limit: 500
      sort: { fields: [frontmatter___title], order: ASC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const WrapperTitle = styled.div`
  position: relative;
  @media (max-width: 1000px) {
    display: block;
  }
`

const WrapperTitleText = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  max-width: 50%;
  background-color: white;
  transform: translateY(-50%);
  padding: 1.5em 1em 1em 1.2em;
  border-radius: 2px;
  flex: 0 0 40%;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 1000px) {
    position: relative;
    width: 100%;
    max-width: 100%;
    transform: translateY(0%);
    top: 0;
    padding: 1.15em 0.75em 0.75em 0.9em;
  }
`

const WrapperTitleImage = styled.div`
  margin-left: 40%;
  max-width: 60%;
  flex: 0 0 60%;
  z-index: 0;
  @media (max-width: 1000px) {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    transform: translateY(0%);
  }
`

const Breadcrumb = styled.div`
  text-align: center;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1em;
  a {
    color: #4f5767;
    text-decoration: none;
  }
  @media (max-width: 678px) {
    font-size: 0.87em;
    margin-bottom: 10px;
  }
`

const H1 = styled.h1`
  color: rgba(44, 44, 44, 0.96);
  font-family: Raleway, "Times new roman", serif;
  @media (max-width: 678px) {
    font-size: 2em;
    line-height: 1.1em;
  }
`

const Intro = styled.p`
  font-size: 1.18em;
  line-height: 158%;
  @media (max-width: 678px) {
    font-size: 1.15em;
    line-height: 155%;
  }
`

const WrapperText = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 1.5em 1.5em 1em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 1em 0.7em 1em;
  }
`
